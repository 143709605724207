import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import WelcomeModal from './components/welcome_modal';
import reportWebVitals from './reportWebVitals';

import GlobalContext from './contexts';

ReactDOM.render(
    <React.StrictMode>
        <GlobalContext>
            <App />
        </GlobalContext>        
    </React.StrictMode>,
    document.getElementById('root')
);

const lastMessageKey = 'last-seen-message';
const lastMessageSeenISO = window.localStorage.getItem(lastMessageKey);
const lastMessageSeen = lastMessageSeenISO ? new Date(lastMessageSeenISO) : new Date(1900);

// If the last message that a user saw was from before
// the hard coded date of the most recent message, then
// show the message.
const lastUpdatedISO = '2025-01-21T05:33:44.004Z';
if (lastMessageSeen < new Date(lastUpdatedISO)) {
    ReactDOM.render(
        <React.StrictMode>
            <WelcomeModal 
                lastUpdated={lastUpdatedISO}
                onHideUntilNextUpdate={() => window.localStorage.setItem(lastMessageKey, new Date().toISOString())}
            />
        </React.StrictMode>,
        document.getElementById('welcome-modal')
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console._THIS_IS_HERE_FOR_SEARCH_ALL_log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
